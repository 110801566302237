/* This file is only for development any style included here will not be deployed */

@font-face {
    font-family: 'vw-head';
    font-weight: 400;
    font-display: swap;
    src: url('fonts/vw/vwhead-regular.woff2') format('woff2'),
    url('fonts/vw/vwhead-regular.woff') format('woff');
}

@font-face {
    font-family: 'vw-head-bold';
    font-weight: 700;
    font-display: swap;
    src: url('fonts/vw/vwhead-bold.woff2') format('woff2'),
    url('fonts/vw/vwhead-bold.woff') format('woff');
}

@font-face {
    font-family: 'vw-head-light';
    font-weight: 200;
    font-display: swap;
    src: url('fonts/vw/vwhead-light.woff2') format('woff2'),
    url('fonts/vw/vwhead-light.woff') format('woff');
}

@font-face {
    font-family: 'vw-text';
    font-weight: 400;
    font-display: swap;
    src: url('fonts/vw/vwtext-regular.woff2') format('woff2'),
    url('fonts/vw/vwtext-regular.woff') format('woff');
}

@font-face {
    font-family: 'vw-text-bold';
    font-weight: 700;
    font-display: swap;
    src: url('fonts/vw/vwtext-bold.woff2') format('woff2'),
    url('fonts/vw/vwtext-bold.woff') format('woff');
}